import { singleton } from "@solvari/common-fe/helpers";
import axios from "axios";
import Echo from "laravel-echo";

import { ziggy } from "@/vue/apps/admin/plugins/ziggy.ts";
import { store } from "@/vue/apps/customer/store/store.js";
import { getEnv } from "@/vue/utils/solvariConfig.ts";

import "pusher-js";

export default singleton(() => {
  return new Echo({
    authEndpoint: ziggy("api.v3.broadcasting.auth"),
    broadcaster: "pusher",
    key: getEnv().integrations.pusher.key,
    cluster: getEnv().integrations.pusher.cluster,
    forceTLS: true,
    authorizer: (channel, options) => ({
      authorize: (socketId, callback) => {
        axios
          .post(
            options.authEndpoint,
            { socket_id: socketId, channel_name: channel.name },
            {
              headers: {
                Authorization: `Bearer ${store().getters["auth/accessToken"]}`,
              },
            },
          )
          .then((response) => callback(false, response.data))
          .catch((error) => callback(true, error));
      },
    }),
  });
});
