import type { LocaleMessageObject } from "vue-i18n";
import type VueI18n from "vue-i18n";

import { type LocaleMorpheus, singleton } from "@solvari/common-fe/helpers";
import axios from "axios";
import { createI18n } from "vue-i18n-composable";

import { getEnv } from "@/vue/utils/solvariConfig";

const i18n = singleton(() => {
  const instance = createI18n({
    locale: getEnv().config.localeCode,
    silentFallbackWarn: true,
    silentTranslationWarn: true,
  });

  void addProVueTranslations(instance, getEnv().config.localeCode);

  return instance;
});

async function addProVueTranslations(i18n: VueI18n, locale: LocaleMorpheus) {
  const { data } = await axios.get<LocaleMessageObject>(
    `${
      getEnv().network.cdn.morpheus
    }/morpheus/translations/${locale}/pro_vue.json`,
  );

  i18n.mergeLocaleMessage(locale, data);
}

export default i18n;
