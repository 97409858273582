import { getEnv } from "@/vue/utils/solvariConfig.ts";

export default () => ({
  loading: true,
  locale: getEnv().config.localeCode,
  availableUsers: [],
  filter: {
    name: "",
    id: null,
    radius: 50,
    zipCode: null,
    slotsAvailable: 0,
    contact: [],
    withDescription: "",
    products: [],
    textSearchTags: [],
    users: [],
    locale: getEnv().config.localeCode,
    notificationFrequency: null,
    notificationFrequencyHour: null,
    notificationFrequencyDay: null,
    notificationFrequencyWeekend: false,
    isFavorite: false,
  },
  meta: {
    filterController: null,
  },
});
